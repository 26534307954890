/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
function Cover() {
  const [formData, setFormData] = useState({
    BrokerName:"",
    MobileNo:"",
    Password:"",
    LicenseNumber:"",
    RenewalDate:"",
    agree: false,
  });
  const [error, setError] = useState([]);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const handleChange = (e) => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
    setError('');
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false); // Close success dialog
    window.location.replace('*')
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://89.116.20.138:3000/broker/brokers", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(response)
      if(response.ok)
      {
        setSuccessDialogOpen(true);

      }else
      {
          const errorMessage = await response.json(); 
          console.log(errorMessage)
          setError(errorMessage);
          throw new Error('Broker request failed');
        
      }
    } catch (error) {
      console.error('Error Sending Error:', error);
    }
  };
  useEffect(()=>{
  handleSubmit;
  // const syntheticEvent = { preventDefault: () => {} }; 
  //   handleSubmit(syntheticEvent);
  },[])

return(
    <CoverLayout image={bgImage}>
          <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>Send Broker Successful</DialogTitle>
        <DialogContent>
          {/* <p>Your registration was successful.</p> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
           Broker Form
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={5}>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
      <MDBox mb={2}>
        <MDInput
          type="text"
          name="BrokerName"
          label="BrokerName"
          variant="standard" 
          fullWidth
          value={formData.BrokerName}
          onChange={handleChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="text"
          name="MobileNo"
          label="MobileNo"
          variant="standard"
          fullWidth
          value={formData.MobileNo}
          onChange={handleChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="password"
          name="Password"
          label="Password"
          variant="standard" 
          fullWidth
          value={formData.Password}
          onChange={handleChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="text"
          name="LicenseNumber"
          label="LicenseNumber"
          variant="standard"
          fullWidth
          value={formData.LicenseNumber}
          onChange={handleChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="date"
          name="RenewalDate"
          variant="standard"
          fullWidth
          value={formData.RenewalDate}
          onChange={handleChange}
        />
      </MDBox>
      
      {error &&  ( // Check if error object exists and has a message property
  <MDTypography variant="body2" color="error" mt={1} textAlign="center">
    {error.error} {/* Render the error message */}
  </MDTypography>
)}
      
      <MDBox mt={4} mb={1}>
        <MDButton variant="gradient" color="info" type="submit" fullWidth>
          Send
        </MDButton>
      </MDBox>
    </MDBox>

          </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
