/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import MDButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// Images
import LogoAsana from "assets/images/small-logos/logo-asana.svg";
import logoGithub from "assets/images/small-logos/github.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";

export default function data(searchQueryT) {
  const Project = ({ image, name,ID, MobileNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
     <MDTypography  name={name} size="sm" >
        {image}
      </MDTypography>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {ID}
      </MDTypography>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{MobileNo}</MDTypography>
    </MDBox>
  </MDBox>
  );



    const [bookingCount, setBookingCount] = useState([]);
    const [Priority, setPriority] = useState([]);
    const [Team, setTeam] = useState([]);
    const [Status, setStatus] = useState([]);
    
      const fetchData = async () => {
        try {
          const [ticketsResponse, PriorityResponse, TeamResponse, StatusResponse] = await Promise.all([
            fetch(`http://89.116.20.138:3000/Tickets`),
            fetch(`http://89.116.20.138:3000/TicketsPriority`),
            fetch(`http://89.116.20.138:3000/TicketsTeam`),
            fetch(`http://89.116.20.138:3000/TicketsStatus`),
          ]);
    
          const [ticketsData, PriorityData, TeamData, StatusData] = await Promise.all([
            ticketsResponse.json(),
            PriorityResponse.json(),
            TeamResponse.json(),
            StatusResponse.json()
          ]);
    
        
    
          // Here you can update the state as needed
          setBookingCount(ticketsData.result);
          setPriority(PriorityData.result);
          setTeam(TeamData.result);
          setStatus(StatusData.result);
          // Update other states as well if needed
    
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, []);
    
  
    

// this code for status
  const [selectedStatus, setSelectedStatus] = useState("");
const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [selectedBrokerIndex, setSelectedBrokerIndex] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState('');
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  
  const handleEditStatusClick = () => {
    setOpenStatusDialog(true);
  };
  
  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
  };
  
  const handleSaveStatus = async () => {
    try {
      const brokerToUpdate = [...bookingCount];
      const ticketId = brokerToUpdate[0]?.TicketID; // Get the TicketID for the broker to update
  
      if (!ticketId) {
        console.error('Error: TicketID not found for the selected broker.');
        return;
      }
  
      const updatedStatus = { StatusID: selectedStatus }; // Updated status object
  
      const response = await fetch(`http://89.116.20.138:3000/Tickets/${ticketId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedStatus), // Only send the updated status ID
      });
  
  
      if (response.ok) {
        console.log('Status updated successfully');
      } else {
        console.error('Error updating status:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  
    setOpenStatusDialog(false); // Close the dialog after saving
  window.location.replace("");

  };
  
  
  
// this code priority
const [openPriorityDialog, setOpenPriorityDialog] = useState(false);

const handlePriorityChange = (event) => {
  setSelectedPriority(event.target.value);
};

const handleEditPriorityClick = () => {
  setOpenPriorityDialog(true);
};

const handleClosePriorityDialog = () => {
  setOpenPriorityDialog(false);
};

const handleSavePriority = async (index) => {
  try {
    // Get the ticket ID for the selected broker
    const ticketId = bookingCount[0]?.TicketID;

    if (!ticketId) {
      console.error('Error: TicketID not found for the selected broker.');
      return;
    }

    // Create the updated priority object
    const updatedPriority = {
      PriorityID: selectedPriority
    };
    // Send PUT request to update the priority in the database
    const response = await fetch(`http://89.116.20.138:3000/Tickets/${ticketId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedPriority),
    });

    if (response.ok) {
      console.log('Priority updated successfully');
    } else {
      console.error('Error updating priority:', response.statusText);
    }
  } catch (error) {
    console.error('Error updating priority:', error);
  }

  setOpenPriorityDialog(false); // Close the dialog after saving
  window.location.replace("");
};



// this code for edit
const [selectedTeam, setSelectedTeam] = useState('');
const [openTeamDialog, setOpenTeamDialog] = useState(false);
const [editable, setEditable] = useState(false);

const handleEditTeamClick = () => {
  setEditable(true);
  setOpenTeamDialog(true);
};

const handleCloseTeamDialog = () => {
  setOpenTeamDialog(false);
};
const handleTeamChange = (event) => {
  setSelectedTeam(event.target.value);
};
const handleSaveTeam = async (index) => {
  try {
    // Get the ticket ID for the selected broker
    const ticketId = bookingCount[0]?.TicketID;

    if (!ticketId) {
      console.error('Error: TicketID not found for the selected broker.');
      return;
    }

    // Create the updated priority object
    const updatedPriority = {
      TeamID: selectedTeam
    };

    // Send PUT request to update the priority in the database
    const response = await fetch(`http://89.116.20.138:3000/Tickets/${ticketId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedPriority),
    });

    if (response.ok) {
      console.log('Priority updated successfully');
    } else {
      console.error('Error updating priority:', response.statusText);
    }
  } catch (error) {
    console.error('Error updating priority:', error);
  }
  setEditable(false);
  setOpenTeamDialog(false);
  window.location.replace("");

};





  let filteredData = bookingCount;
  if (searchQueryT) {
    filteredData = bookingCount.filter(broker =>
      (
        broker?.Pawnbroker?.BrokerName.toLowerCase() 
        // broker.BrokerName.toUpperCase() + 
        // broker.City.toLowerCase() + 
        // // broker.City.toUpperCase() +  

        // broker.MobileNo.toLowerCase() 
        // // broker.MobileNo.toUpperCase()
      ).includes(searchQueryT.toLowerCase())
    );
  }
  


  const rows = filteredData.map((broker, index) => {
    const project = (
      <Project  image={<img
        src={`http://89.116.20.138:3000/${broker?.Pawnbroker?.Photo}`}
        alt="Broker"
        style={{ borderRadius: '100px', height: "50px", width: "50px" }}
      />}  name={broker?.Pawnbroker?.BrokerName} MobileNo={broker?.Pawnbroker?.MobileNo} />
    );
  
    const Titel = (
      <MDTypography component="a" href="#"   fontWeight="medium">
        {broker?.TicketIssue?.Name}
        <MDTypography
  style={{ 
    width: "300px", 
    height: "100px",
    border: "none",
    padding:"5px",
    fontFamily:"sans-serif",
    overflowY: "auto", 
  }}
  readOnly
>
  {broker.Description}
</MDTypography>


  
</MDTypography>


);


  
       
  
    const priorityOptions = Priority.map((priority, index) => (
      <MenuItem key={index} value={priority.PriorityID}>{priority.Name}</MenuItem>
    ));
  
    const priority = (
      <MDTypography component="a" color="text" variant="caption" fontWeight="medium">
         {broker?.TicketPriority?.Name}

<br />
<br />
<MDButton onClick={handleEditPriorityClick}>Edit</MDButton>
        <Dialog open={openPriorityDialog} onClose={handleClosePriorityDialog}>
          <DialogTitle>Select Priority</DialogTitle>
          <DialogContent>
            <Select value={selectedPriority} onChange={handlePriorityChange}>
              <MenuItem value="">Select Priority</MenuItem>
              {priorityOptions}
            </Select>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleSavePriority}>Save</MDButton>
            {/* <MDButton onClick={() => { handleSavePriority(); handleButtonClick(); }}>Save</MDButton> */}


            <MDButton onClick={handleClosePriorityDialog}>Cancel</MDButton>
          </DialogActions>
        </Dialog>
      </MDTypography>
    );
  
    const statusOptions = Status.map((status, index) => (
      <MenuItem key={index} value={status.StatusID}>{status.Name}</MenuItem>
    ));
  
    const status = (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {broker?.TicketStatus?.Name}

        <br />
        <br />
<MDButton onClick={handleEditStatusClick}>Edit</MDButton>


        <Dialog open={openStatusDialog} onClose={handleCloseStatusDialog}>
          <DialogTitle>Select Status</DialogTitle>
          <DialogContent>
            <Select value={selectedStatus} onChange={handleStatusChange}>
              {statusOptions}
            </Select>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleSaveStatus}>Save</MDButton>
            <MDButton onClick={handleCloseStatusDialog}>Cancel</MDButton>
          </DialogActions>
        </Dialog>
      </MDTypography>
    );
  
    const teamOptions = Team.map((team, index) => (
      <MenuItem key={index} value={team.TeamID}>{team.Name}</MenuItem>
    ));
  
    const team = (
       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {broker?.TicketTeam?.Name}

        <br />
        <br />
<MDButton onClick={handleEditTeamClick}>Edit</MDButton>
<Dialog open={openTeamDialog} onClose={handleCloseTeamDialog}>
          <DialogTitle>Edit Team</DialogTitle>
          <DialogContent>
          <Select value={selectedTeam} onChange={handleTeamChange}>
              {teamOptions}
            </Select>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleSaveTeam}>Save</MDButton>
            <MDButton onClick={handleCloseTeamDialog}>Cancel</MDButton>
          </DialogActions>
        </Dialog>
      </MDTypography>
       
    );
  
    const action = (
      <MDTypography component="a" href="#" color="text">
        <Icon>more_vert</Icon>
      </MDTypography>
      
    );
  
    return {
      project: project,
      Titel: Titel,
      priority: priority,
      team: team,
      status: status,
      action: action,
    };
  });
  
  
 

  return{
   columns: [
        { Header: "Broker", accessor: "project", width: "30%", align: "left" },
        { Header: "Title", accessor: "Titel", align: "left" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "priority", accessor: "priority", align: "center" },
        { Header: "team", accessor: "team", align: "center" },
        // { Header: "action", accessor: "action", align: "center" },
      ],

    rows: rows,
    
    
};
}




