/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";
import { useState, useEffect } from "react";

function BillingInformation() {
  const [billingData, setBillingData] = useState([]);

  useEffect(() => {
    // Fetch billing information from API
    fetch('http://89.116.20.138:3000/Payments')
      .then(response => response.json())
      .then(data => {
        console.log(data.data)
        setBillingData(data.data); // Assuming data is an array of billing information objects
      })
      .catch(error => {
        console.error('Error fetching billing information:', error);
      });
  }, []);

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Payment History
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {billingData.map((billInfo, index) => (
            <Bill
              key={index} // Assuming each bill has a unique identifier
              name={billInfo?.Pawnbroker?.BrokerName}
              company={billInfo.BrokerID}
              email={billInfo.Amount}
              vat={billInfo.PayDate}
              noGutter={billInfo.MobileNo} // Assuming this property is present in the bill data
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
