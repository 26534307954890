/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// import table from '../../tables/index';
import { useState, useEffect } from "react";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import MDButton from "components/MDButton";
import { Avatar } from "@mui/material";





export default function data(searchQuery) {
  const Broker = ({ image, ID, Name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography  name={Name} size="sm" >
        {image}
      </MDTypography>
      
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {ID}
        </MDTypography>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {Name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  // Fetching data from API
  
  const [bookingCount, setBookingCount] = useState([]);
  useEffect(() => {
    const fetchData = async (index) => {
      try {
        const response = await fetch(`http://89.116.20.138:3000/broker/brokers`);
        const data = await response.json();

        setBookingCount(data.result);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (index) => {
    const updatedBookingCount = [...bookingCount];
    updatedBookingCount[index].editMode = true;
    setBookingCount(updatedBookingCount);
  };


  const handleSaveClick = async (index) => {
    try {
      const updatedBroker = bookingCount[index];
     await fetch(`http://89.116.20.138:3000/broker/brokersstatus/${updatedBroker.BrokerID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ IsActive: updatedBroker.IsActive })
      });
      console.log(
        "Update Succesfully"
       )
         // Disable edit mode after saving
      updatedBroker.editMode = false;
  
      // Update the state with the modified data
      const updatedBookingCount = [...bookingCount];
      updatedBookingCount[index] = updatedBroker;
      setBookingCount(updatedBookingCount);
        
  
     
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  


  const handleStatusChange = async (index, newStatus) => {
    try {
      const updatedBookingCount = [...bookingCount];
      updatedBookingCount[index].IsActive = newStatus;
      console.log(updatedBookingCount)
      setBookingCount(updatedBookingCount);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  

  const renderStatusElement = (index) => {
    const status = bookingCount[index].IsActive;
    if (bookingCount[index].editMode) {
      return (
        <select value={status} onChange={(e) => handleStatusChange(index, e.target.value)}>
          <option value="">Choose Status</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
          <option value="Demo">Demo</option>
        </select>
      );
    } else {
      return (
        <MDBox ml={-1}>
          <MDBadge badgeContent={status} color={status === 'Active' ? 'success' : status === 'Inactive' ? 'dark' : 'error'} variant="gradient" size="sm" />
        </MDBox>
      );
    }
  };
  
  let filteredData = bookingCount;
if (searchQuery) {
  filteredData = bookingCount.filter(broker =>
    (
      (broker.BrokerName?.toLowerCase() || '') + 
      (broker.City?.toLowerCase() || '') + 
      (broker.MobileNo?.toLowerCase() || '')
    ).includes(searchQuery.toLowerCase())
  );
}


  
  


  const rows = filteredData.map((broker, index) => {
   
    const authorElement = (
      <Broker 
      


      image={<img
        src={`http://89.116.20.138:3000/${broker.Photo}`}
        alt="Broker"
        style={{ borderRadius: '100px', height: "50px", width: "50px" }}
      />
      
    
    } ID={broker.BrokerID} Name={broker.BrokerName} email={broker.MobileNo} />
    );
    const functionElement = (
      <Job title={broker.City} description={[broker.Dist, broker.Address]} />
    );

    const statusElement = renderStatusElement(index);
    
    const employedElement = (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {broker.RenewalDate}
      </MDTypography>
    );

    let displayFcmId = null ;

    if (broker && broker.FCMId) {
      const shortenedFcmId = broker.FCMId.substring(0, 8);
      // Check if FCMId length is greater than five characters
      displayFcmId = broker.FCMId.length > 8 ? `${shortenedFcmId}......` : broker.FCMId;
    }
   
    const employedElement1 = (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    {displayFcmId}
  </MDTypography>
    );
    const actionElement = (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => bookingCount[index].editMode ? handleSaveClick(index) : handleEditClick(index)}>
        {bookingCount[index].editMode ? 'Save' : 'Edit'}
      </MDTypography>
    );

    return {
      broker: authorElement,
      function: functionElement,
      status: statusElement,
      employed: employedElement,
      FCMId: employedElement1,
      action: actionElement,
    };
  });

  return {
    columns: [
      { Header: "broker", accessor: "broker", width: "45%", align: "left" },
      { Header: "FCMId", accessor: "FCMId", align: "center" },
      { Header: "City", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "employed", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: rows
  };
}






























