/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function Cover() {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Password: '',
    agree: false,
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const handleChange = (e) => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false); // Close success dialog
    window.location.replace('/authentication/sign-in')
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const protocol = window.location.protocol; // Get the protocol (http: or https:)
    const baseUrl = protocol === 'https:' ? 'https://89.116.20.138:3000/admin/admins' : 'http://89.116.20.138:3000/admin/admins';
    try {
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        
      });
      console.log(response)
      if (response.ok) {
        setSuccessDialogOpen(true); // Open success dialog
       }else{
        throw new Error('Signup request failed');
      }
      // Handle successful signup
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };
return (
    <CoverLayout image={bgImage}>
      <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>Registration Successful</DialogTitle>
        <DialogContent>
          <p>Your registration was successful.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Company Name
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
      <MDBox mb={2}>
        <MDInput
          type="text"
          name="Name"
          label="Name"
          variant="standard" 
          fullWidth
          value={formData.Name}
          onChange={handleChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="email"
          name="Email"
          label="Email"
          variant="standard"
          fullWidth
          value={formData.Email}
          onChange={handleChange}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="password"
          name="Password"
          label="Password"
          variant="standard"
          fullWidth
          value={formData.Password}
          onChange={handleChange}
        />
      </MDBox>
      <MDBox display="flex" alignItems="center" ml={-1}>
        <Checkbox />
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;I agree the&nbsp;
        </MDTypography>
        <MDTypography
          component="a"
          href="#"
          variant="button"
          fontWeight="bold"
          color="info"
          textGradient
        >
          Terms and Conditions
        </MDTypography>
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton variant="gradient" color="info" type="submit" fullWidth>
          Sign up
        </MDButton>
      </MDBox>
    </MDBox>
    <MDBox mt={3} mb={1} textAlign="center">
         <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
