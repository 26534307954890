/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";




// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { MenuItem, Select } from "@mui/material";
// import Select from "assets/theme/components/form/select";

// Material Dashboard 2 React components
function Invoice() {
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false); // Close success dialog
    window.location.replace('')
  };
  const [formData, setFormData] = useState({
    BrokerID: '',
    PayDate: '',
    Amount: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetch('http://89.116.20.138:3000/broker/brokers')
      .then(response => response.json())
      .then(data => {
        setPaymentOptions(data.result); 
      })
      .catch(error => {
        console.error('Error fetching payment options:', error);
      });
  }, []);

  const handleChange = (e) => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://89.116.20.138:3000/Payments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if(response.ok)
      {
        setSuccessDialogOpen(true);

      }else
      {
          const errorMessage = await response.json(); 
          console.log(errorMessage)
          setError(errorMessage);
          throw new Error('Broker request failed');
        
      }
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  useEffect(() => {
    handleSubmit;
  }, []);

  return (
    <MDTypography variant="h4" fontWeight="medium" color="black"   sx={{ paddingLeft: '15px' }}>
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      pr={1}
      mb={1}
    >
        <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>Payment Enter Successful</DialogTitle>
        <DialogContent>
          {/* <p>Your registration was successful.</p> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
   

      <MDBox pr={5} pb={1} px={12}>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
          <MDBox mb={2}>
            <Select
              label="BrokerID"
              name="BrokerID"
              onChange={handleChange}
              fullWidth
              defaultValue="Select"
              sx={{ padding: '8px' }}
            >
              <MenuItem value={'Select'} >Select Broker</MenuItem>
              {paymentOptions.map(option => (
                <MenuItem key={option.id} value={option.BrokerID}>
                  {option.BrokerName} {/* Assuming BrokerName is a string */}
                </MenuItem>
              ))}
            </Select>
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="date"  name="PayDate" value={formData.PayDate} onChange={handleChange} fullWidth />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="text" label="Amount" name="Amount" value={formData.Amount} onChange={handleChange} fullWidth />
          </MDBox>
          {error && (
            <MDTypography variant="body2" color="error" mt={1} textAlign="center">
              {error.message}
            </MDTypography>
          )}
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" type="submit" color="info" fullWidth>
              Enter
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  </MDTypography>

  );
}



export default Invoice;
