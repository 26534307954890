/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Search, SearchOff } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function Tables() {
  const [searchQueryT, setSearchQueryT] = useState('');
  const { columns: pColumns, rows: pRows } = projectsTableData(searchQueryT);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsT, setSearchResultsT] = useState([]);
  const { columns, rows } = authorsTableData(searchQuery);
 

 const [formData, setFormData] = useState({
    Name: '',
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const handleChange = (e) => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false); // Close success dialog
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
      const response = await fetch('http://89.116.20.138:3000/TicketIssue/issues', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        
      });
      if (response.ok) {
        setSuccessDialogOpen(true); // Open success dialog
       }else{
        throw new Error('Send request failed');
      }
    } catch (error) {
      console.error('Error Send:', error);
    }
  };




  const handleSearch = async () => {
    try {
      const data = await fetch(`http://89.116.20.138:3000/broker/brokersByName/${searchQuery}`); // Fetch data based on searchQuery
      
      setSearchResults(data);
      
    } catch (error) {
      console.log(error);
    }
  };
  useEffect   (() => {
  handleSearch();
  }, [searchQuery]);

  // const handleSearchT = async () => {
  //   try {
  //     const data = await fetch(`http://192.168.0.134:3000/Tickets/name/${searchQueryT}`); // Fetch data based on searchQuery
      
  //     setSearchResultsT(data);
      
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  // useEffect   (() => {
  // handleSearch();
  // }, [searchQueryT]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>Enter Issiue Successful</DialogTitle>
        <DialogContent>
          <p>Your Issiue Enter was successful.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Broker Table
                </MDTypography>
              </MDBox>
              <MDBox pr={3} pt={2} sx={{ textAlign: 'right' }}>
                <MDInput label="Search here"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onClick={handleSearch}
                />
                {/* <IconButton onClick={handleSearch}>
                  <Search />
                </IconButton> */}
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: searchResults.length > 0 ? searchResults: rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Tickets Table
                </MDTypography>
                
              </MDBox>
              <MDBox display="flex" justifyContent="space-between" pr={5} pl={5}>
  {/* Left side: Enter Issue */}
  <MDBox pr={3} pt={2}>
    <MDInput
      type="text"
      name="Name"
      label="Enter Issue"
      value={formData.Name}
      onChange={handleChange}
    />
    <MDButton
      type="submit"
      style={{ backgroundColor: '#1e90ff', color: '#fff', marginLeft: '5px' }}
      onClick={handleSubmit}
    >
      Enter
    </MDButton>
  </MDBox>

  {/* Right side: Search bar */}
  {/* <MDBox pl={3} pt={2}>
    <MDInput
      label="Search here"
      value={searchQueryT}
      onChange={(e) => setSearchQueryT(e.target.value)}
      onClick={handleSearchT}
    />
  </MDBox> */}
</MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;
